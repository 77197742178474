//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from 'axios';
import qs from 'qs';
export default {
  name: 'ReportingForm',
  data: function data() {
    return {
      show: false,
      loading: true,
      users: null,
      reporting_updated: {
        id: null,
        name: null,
        receivers: [],
        line_token: null,
        telegram_token: null,
        telegram_chatid: null,
        applications: [],
        cycle: 'daily',
        schedule: {
          hour: null,
          day: null,
          date: null
        },
        period: null,
        format: null
      },
      notification: '',
      dismissCountDown: 0,
      step: 0
    };
  },
  methods: {
    open: function open(param) {
      this.getUsers();

      if (param) {
        this.reporting_updated = this.$utility.copy(param);
      }

      console.log(this.reporting_updated);
      this.show = true;
    },
    getUsers: function getUsers() {
      var _this = this;

      var query_string = '';
      var params = {
        ipp: 1000,
        page: 1,
        sort: 'auth_level__level',
        order: 'asc'
      };

      if (params) {
        query_string = '?' + qs.stringify(params);
      }

      this.loading = true;
      axios.get("/api/users/".concat(query_string)).then(function (result) {
        _this.users = _this.$utility.copy(result.data.list);
      }).catch(function (error) {
        console.error(error);
      }).finally(function () {
        _this.loading = false;
      });
    },
    onCancel: function onCancel() {
      this.show = false;
      this.reporting_updated.id = null;
      this.reporting_updated.name = null;
      this.reporting_updated.applications = [];
      this.reporting_updated.cycle = 'daily';
      this.reporting_updated.schedule = {
        hour: null,
        day: null,
        date: null
      };
      this.reporting_updated.period = null;
      this.reporting_updated.format = null;
      this.reporting_updated.receivers = [];
      this.reporting_updated.line_token = null;
      this.reporting_updated.telegram_token = null;
      this.reporting_updated.telegram_chatid = null;
    },
    onSubmit: function onSubmit() {
      if (!this.reporting_updated.name) {
        this.notification = this.$t('cloud.title.validate.name');
        this.dismissCountDown = 5;
        return;
      }

      if (!this.reporting_updated.cycle) {
        this.notification = this.$t('cloud.title.validate.cycle');
        this.dismissCountDown = 5;
        return;
      }

      if (this.reporting_updated.cycle === 'daily') {
        if (this.reporting_updated.schedule.hour !== 0 && !this.reporting_updated.schedule.hour) {
          this.notification = this.$t('cloud.title.validate.schedule');
          this.dismissCountDown = 5;
          return;
        }
      }

      if (this.reporting_updated.cycle === 'weekly') {
        if (this.reporting_updated.schedule.hour !== 0 && !this.reporting_updated.schedule.hour) {
          this.notification = this.$t('cloud.title.validate.schedule');
          this.dismissCountDown = 5;
          return;
        }

        if (this.reporting_updated.schedule.day !== 0 && !this.reporting_updated.schedule.day) {
          this.notification = this.$t('cloud.title.validate.schedule');
          this.dismissCountDown = 5;
          return;
        }
      }

      if (this.reporting_updated.cycle === 'monthly') {
        if (this.reporting_updated.schedule.hour !== 0 && !this.reporting_updated.schedule.hour) {
          this.notification = this.$t('cloud.title.validate.schedule');
          this.dismissCountDown = 5;
          return;
        }

        if (this.reporting_updated.schedule.date !== 0 && !this.reporting_updated.schedule.date) {
          this.notification = this.$t('cloud.title.validate.schedule');
          this.dismissCountDown = 5;
          return;
        }
      }

      if (!this.reporting_updated.period) {
        this.notification = this.$t('cloud.title.validate.period');
        this.dismissCountDown = 5;
        return;
      }

      if (!this.reporting_updated.format) {
        this.notification = this.$t('cloud.title.validate.format');
        this.dismissCountDown = 5;
        return;
      }

      if ((!this.reporting_updated.receivers || this.reporting_updated.receivers.length <= 0) && !this.reporting_updated.line_token && (!this.reporting_updated.telegram_token || !this.reporting_updated.telegram_chatid)) {
        this.notification = this.$t('cloud.title.validate.receivers');
        this.dismissCountDown = 5;
        return;
      }

      this.$emit('payload', this.$utility.copy(this.reporting_updated));
      this.onCancel();
      this.show = false;
    },
    resetSchedPeriod: function resetSchedPeriod() {
      this.reporting_updated.schedule = {
        hour: null,
        day: null,
        date: null
      };
      this.reporting_updated.period = null;
    }
  }
};