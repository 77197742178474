//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from 'axios';
import qs from 'qs';
export default {
  name: 'AlarmReportingHistory',
  data: function data() {
    return {
      loading: false,
      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      count: 0,
      list: [],
      FIELDS: [{
        key: 'reporting_date',
        label: this.$t('cloud.title.reporting_date')
      }, {
        key: 'name',
        label: this.$t('cloud.title.name')
      }, {
        key: 'format',
        label: this.$t('cloud.title.data_format')
      }, {
        key: 'count',
        label: this.$t('cloud.title.data_count')
      }, {
        key: 'dest_type',
        label: this.$t('cloud.title.recv_format')
      }, {
        key: 'result',
        label: this.$t('cloud.title.result')
      }]
    };
  },
  mounted: function mounted() {
    this.initFetch();
  },
  methods: {
    initFetch: function initFetch() {
      this.getList();
    },
    getList: function getList() {
      var _this = this;

      var query_string = '';
      var params = {
        ipp: 10,
        page: this.pageCurrent
      };

      if (params) {
        query_string = '?' + qs.stringify(params);
      }

      this.list = [];
      axios.get("/api/alarm/reporting-history/".concat(query_string)).then(function (result) {
        _this.list = _this.$utility.copy(result.data.list);
        _this.pageTotal = result.data.page.total;
        _this.pageCurrent = result.data.page.current;
        _this.count = result.data.page.count;

        _this.list.map(function (el) {
          el.reporting_date = _this.$utility.GetDateTimeStr("$yyyy-$mm-$dd $HH:$MM:$ss", new Date(el.created_at));
        });
      }).catch(function (error) {
        console.error(error);
      });
    },
    onUpdatePage: function onUpdatePage() {
      this.pageDirectSet = "" + this.pageCurrent;
      this.getList();
    },
    onChangePageDirectSet: function onChangePageDirectSet() {
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = "" + this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      this.getList();
    },
    onEdit: function onEdit(index) {
      console.log('edit', index);
    },
    onDelete: function onDelete(index) {
      console.log('delete', index);
    },
    onOps: function onOps(cmd, index) {
      if (cmd === 'edit') {
        this.onEdit(index);
      } else if (cmd === 'delete') {
        this.onDelete(index);
      }
    }
  }
};