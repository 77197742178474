//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Reporting from './Reporting.vue';
import History from './History.vue';
export default {
  name: 'AlarmReporting',
  components: {
    Reporting: Reporting,
    History: History
  },
  computed: {},
  data: function data() {
    return {
      t: 'reporting'
    };
  }
};