//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from 'axios';
import qs from 'qs';
import ReportingForm from './ReportingForm.vue';
export default {
  name: 'AlarmReportingSetting',
  components: {
    ReportingForm: ReportingForm
  },
  data: function data() {
    return {
      loading: false,
      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      count: 0,
      list: [],
      FIELDS: [{
        key: 'name',
        label: this.$t('cloud.title.name')
      }, {
        key: 'sched_txt',
        label: this.$t('cloud.title.schedule')
      }, {
        key: 'period_txt',
        label: this.$t('cloud.title.period')
      }, {
        key: 'report_at',
        label: this.$t('cloud.title.last_reported_at')
      }, {
        key: 'ops',
        label: this.$t('cloud.title.operation'),
        _style: 'width:100px; text-align: center;'
      }]
    };
  },
  mounted: function mounted() {
    this.initFetch();
  },
  methods: {
    initFetch: function initFetch() {
      this.getList();
    },
    getList: function getList() {
      var _this = this;

      var query_string = '';
      var params = {
        ipp: 10,
        page: this.pageCurrent
      };

      if (params) {
        query_string = '?' + qs.stringify(params);
      }

      this.list = [];
      axios.get("/api/alarm/reporting/".concat(query_string)).then(function (result) {
        _this.list = _this.$utility.copy(result.data.list);

        _this.list.map(function (el) {
          var days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
          el.sched_txt = _this.$t("cloud.title.period_".concat(el.cycle)) + ' ';

          if (el.schedule.date) {
            el.sched_txt += el.schedule.date + ', ';
          }

          if (el.schedule.day) {
            el.sched_txt += _this.$t("cloud.title.dow_".concat(days[el.schedule.day])) + ', ';
          }

          if (el.schedule.hour) {
            el.sched_txt += _this.$utility.GetHourStr(el.schedule.hour);
          }

          el.period_txt = _this.$t("cloud.title.period_".concat(el.period));
          el.report_at = el.reported_at ? _this.$utility.GetDateTimeStr("$yyyy-$mm-$dd $HH:$MM:$ss", new Date(el.reported_at)) : '';
        });

        _this.pageTotal = result.data.page.total;
        _this.pageCurrent = result.data.page.current;
        _this.count = result.data.page.count;
      }).catch(function (error) {
        console.error(error);
      });
    },
    onUpdatePage: function onUpdatePage() {
      this.pageDirectSet = "" + this.pageCurrent;
      this.getList();
    },
    onChangePageDirectSet: function onChangePageDirectSet() {
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = "" + this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      this.getList();
    },
    onAdd: function onAdd() {
      this.$refs.ReportingForm.open();
    },
    onPayload: function onPayload(payload) {
      var _this2 = this;

      var req = null;
      this.$confirm(this.$t('cloud.title.confirm_content2'), this.$t('cloud.title.confirm_title'), {
        confirmButtonText: this.$t('button.submit'),
        cancelButtonText: this.$t('button.cancel'),
        type: 'warning'
      }).then(function () {
        if (payload.id) {
          req = axios.put("/api/alarm/reporting/".concat(payload.id, "/"), payload);
        } else {
          req = axios.post("/api/alarm/reporting/", payload);
        }

        req.then(function () {
          _this2.getList();

          _this2.$notify.success({
            title: _this2.$t('cloud.title.success'),
            message: _this2.$t('cloud.title.request_done'),
            offset: 30
          });
        }).catch(function (error) {
          console.error(error);

          _this2.$notify.error({
            title: _this2.$t('cloud.title.error'),
            message: error.response.data.error_detail,
            offset: 30
          });
        });
      });
    },
    onEdit: function onEdit(item) {
      this.$refs.ReportingForm.open(item);
    },
    onDelete: function onDelete(item) {
      var _this3 = this;

      this.$confirm(this.$t('cloud.title.confirm_remove2'), this.$t('cloud.title.confirm_title'), {
        confirmButtonText: this.$t('button.submit'),
        cancelButtonText: this.$t('button.cancel'),
        type: 'warning'
      }).then(function () {
        axios.delete("/api/alarm/reporting/".concat(item.id, "/")).then(function () {
          _this3.getList();

          _this3.$notify.success({
            title: _this3.$t('cloud.title.success'),
            message: _this3.$t('cloud.title.request_done'),
            offset: 30
          });
        }).catch(function (error) {
          console.error(error);

          _this3.$notify.error({
            title: _this3.$t('cloud.title.error'),
            message: error.response.data.error_detail,
            offset: 30
          });
        });
      });
    },
    onOps: function onOps(cmd, index, item) {
      if (cmd === 'edit') {
        this.onEdit(item);
      } else if (cmd === 'delete') {
        this.onDelete(item);
      }
    }
  }
};